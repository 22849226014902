@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html[dir="rtl"] body {
  direction: rtl;
  text-align: right !important;
}

html[dir="ltr"] body {
  direction: ltr;
  text-align: left;
}
* {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
  padding: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rtl {
  direction: rtl;
  text-align: right;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Poppins", sans-serif !important;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

/*################################   Typography classes    ###################################*/
.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

/*################################   Typographyclasses    ###################################*/

/*################################   helper classes    ###################################*/
.textcolorgreen {
  color: #44b876;
}
.textcolorblue {
  color: #2d358c;
}

.textcolorwhite {
  color: white;
}

.textcolorgray {
  color: #7d7d7d;
}

.P1rem {
  padding: 1rem;
}

.P2rem {
  padding: 2rem;
}

.P3rem {
  padding: 3rem;
}

.P4rem {
  padding: 4rem;
}

.P5rem {
  padding: 5rem;
}

.M1rem {
  margin: 1rem;
}

.M2rem {
  margin: 2rem;
}

.M3rem {
  margin: 3rem;
}

.M4rem {
  margin: 4rem;
}

.M5rem {
  margin: 5rem;
}
.center {
  display: flex;
  align-items: center;
}

/*################################   helper classes    ###################################*/

/*################################   Navbar    ###################################*/
.top-navbar {
  background-color: #2d358c;
  color: #fff;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  /* Ensure the TopNavbar is above other content */
}

.top-navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-navbar .container .form-select {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white; /* Text color in the select box */
  background-color: transparent;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-image: url(../svgs/downArrowW.svg);
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: none;
  border-radius: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.87px;
  margin: 0 !important;
}

.top-navbar .container .form-select option {
  background-color: white; /* Background color of options */
  color: black; /* Text color of options */
  border: none !important;
}

.top-navbar .container .form-select option:hover {
  background-color: white; /* No change in background on hover */
  color: black; /* No change in text color on hover */
  border: none !important;
}

.top-navbar .container .form-select option:focus {
  background-color: white; /* No change in background on focus */
  color: black; /* No change in text color on focus */
  border: none !important;
}
/* Remove default blue color for selected option */
.top-navbar .container .form-select option:checked {
  background-color: white; /* Ensure selected option background is white */
  color: black; /* Ensure selected option text color is black */
}
.top-navbar .container  .form-select:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.left,
.right {
  display: flex;
  align-items: center;
}

.top-navbar .email,
.phone {
  margin-right: 20px;
}

.top-navbar .right .anticon svg {
  width: 17.09px;
  height: 16.46px;
  top: 0.45px;
  gap: 0px;
}
.top-navbar .right a .fbLogo {
  width: 17.09px;
  height: 16.46px;
  top: 0.45px;
  gap: 0px;
}
.top-navbar .right a {
  color: #fff;
  margin-left: 10px;
  font-size: 20px;
}

.top-navbar .right a:hover {
  color: #fff;
  opacity: 0.8;
}

.custom-flags-select {
  position: relative;
  display: inline-block;
}
.custom-flags-select div {
  padding-bottom: 0px !important;
}

/* Hide the default arrow */
.custom-flags-select .flags-select__button::after {
  display: none;
}

.flags-select__button {
  background: transparent;
  color: #fff; /* Adjust color as needed */
  border: 1px solid #ccc; /* Adjust border as needed */
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px; /* Adjust width as needed */
  position: relative;
}

.flags-select__button:focus {
  box-shadow: none;
}

.flags-select__option {
  color: #000;
}

.flags-select__label {
  margin-left: 8px;
}
/* LanguageDropdown.css */
/* Set text color of button */
.custom-flags-select button {
  color: white;
}

/* Set text color of options */
.custom-flags-select ul {
  color: black;
}

/* Hide the default arrow */
.custom-flags-select .ReactFlagsSelect-module_selectBtn__19wW7:after,
.custom-flags-select .ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
  content: none;
}

/* Position the custom icon */
.custom-dropdown-icon {
  position: absolute;
  top: 53%;
  right: 5px;
  transform: translateY(-50%);
  pointer-events: none;
}
.custom-dropdown-icon svg {
  width: 11.09px !important;
  height: 11.46px !important;
}

/*################################  Navbar middle style  ###################################*/
.MiddleNavBar {
  margin-top: 3rem;
  padding: 2rem 0rem !important;
}

.MiddleNavBar .NavBarDropD {
  color: #37393f;
  text-decoration: none;
}

.MiddleNavBar .navbar-nav {
  align-items: center;
}

.MiddleNavBar .navbar-nav .nav-link {
  color: #37393f;

  font-size: 15px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: center;
  margin-left: 1rem;
}

.MiddleNavBar .navbar-nav .NavSignUpBtn {
  padding: 15px 20px 15px 20px !important;
  gap: 10px;
  border-radius: 5px;

  color: white !important;
}
.MiddleNavBar .navbar-nav .SMEbtn {
  background-color: #44b876;
}
.MiddleNavBar .navbar-nav .Individualbtn {
  background-color: #2d358c;
}

.MiddleNavBar .NavBarDropD .menu .anticon {
  vertical-align: -0.135em !important;
}

.MiddleNavBar .NavBarDropD .menu .anticon svg {
  width: 10px;
  height: 13px;
}

/*################################  Navbar middle style  ###################################*/

/*################################  Navbar  style  ###################################*/

/*################################  WelcomeSection style  ###################################*/

#WelcomeSection {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent), url("../images/section1.png");
  background-position: center;
  background-size: cover;
  min-height: 376px;
  /* height: 550px; */
  height: 450px;

  display: flex;
  align-items: center;

  padding: 20px; /* Add padding for better spacing */
}


.WelcomeSection-content {

  margin-left: 8rem;
  max-width: 1200px; /* Limit the width */
}
.content-right{
  direction: rtl;
  text-align: right;
  transform: scale(-1, 1);
}

.WelcomeSection-content h5 {
  font-size: 15px;
  font-weight: 500;
  line-height: 26.31px;
}

.WelcomeSection-content h1 {
  /* font-size: 45px; */
  font-weight: 700;
  line-height: 55px;
}

.WelcomeSection-content p {
  font-size: 14px;
  font-weight: 500;
  line-height: 26.31px;

  margin-top: 30px;
  margin-bottom: 35px;
}

.WelcomeSection-content button {
  padding: 19px 27px 19px 27px;
  gap: 10px;

  background-color: #44b876;
  color: white;
}

/*################################  WelcomeSection  style  ###################################*/

/*################################  BusinessSection style  ###################################*/

#BusinessSection {
  background-color: #ffff;
}

.BusinessSection {
  display: flex;
  justify-content: center;
  padding: 6rem 0rem;
}

.BusinessSection .BSleft {
  /* margin-right: 6rem; */
}

.BusinessSection .BSleft .BSleft-content {
  max-width: 400px;
}
#contactBusinessSection .BSleft .BSleft-content {
  max-width: 500px;
}

.BusinessSection .BSleft .BSleft-content .ImgContainer {
  max-width: 100%;
  min-width: 331px;
}

.BusinessSection .BSleft .BSleft-content .ImgContainer .BSleftimg {
  width: 100%;
}

.BusinessSection .BSleft .BSleft-content h4 {
  font-size: 24px;

  line-height: 36.3px;

  margin-top: 1rem;
  overflow-wrap: break-word;
}

.BusinessSection .BSleft .BSleft-content p {
  font-size: 13px;
  font-weight: 400;
  color: #7d7d7d;
  line-height: 26.31px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.BusinessSection .BSleft .BSleft-content .InTouchbtn {
  border: none;

  border-bottom: 2px solid;
  padding: 0;
  background-color: white;
  /* font-family: Inter; */
  font-size: 18px;
  font-weight: 600;
}

.BusinessSection .BSRight .BSRight-content .ant-list-item {
  display: flex;
  justify-content: flex-start;
  border: none;
}


.BusinessSection .BSRight .BSRight-content {
  max-width: 637px;
  padding-left: 1.5rem;
}

.BusinessSection .BSRight .BSRight-content h5 {
  font-size: 16px;
  font-weight: 500;
}

.BusinessSection .BSRight .BSRight-content h3 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.BusinessSection .BSRight .BSRight-content p {
  font-weight: 400;
}

.BusinessSection .BSRight .BSRight-content button {
  padding: 19px 30px 19px 30px;
  gap: 10px;
  border-radius: 5px;

  background-color: #2d358c;
  border: none;
  margin-top: 1.5rem;
  color: white;
}

.BusinessSection .BSRight .BSRight-content .ant-list {
  margin-bottom: 3rem;
}
.BusinessSection .BSRight .BSRight-content .ant-list-item .ListItemSpan {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
#contactBusinessSection .BSRight .BSRight-content .ant-list-item .ListItemSpan {
  margin-right: 10px;
  justify-content: center;
  background: #F4F4F4;
  width: 62px;
  min-width: 62px;
  height: 62px;
  display: flex;
  border-radius: 50px;
  align-items: center;
}
#contactBusinessSection .BSRight .BSRight-content .ant-list-item .ListItemSpan .anticon svg {

  color: #44B876;
  font-size: 26px;
}
#contactBusinessSection .BSRight .BSRight-content .ant-list-item .ListItemSpan  .anticon-phone {

  rotate: 100deg;
}
#contactBusinessSection .BSRight .BSRight-content .ListContent h4 {

  font-size: 15px;
  font-weight: 400;
  line-height: 21.63px;

  color: #7D7D7D;
  margin: 0;
}
#contactBusinessSection .BSRight .BSRight-content .ListContent p {

  font-size: 18px;
  font-weight: 400;
  line-height: 27px;

  margin: 0;
}
.BusinessSection .BSRight .BSRight-content .ant-list-item .ListItemtext {
  font-family: Poppins;
  font-size: 19px;
  font-weight: 400;
  line-height: 30px;
}

.BusinessSection .BSRight .card-content .ant-card-body {
  display: flex;
  align-items: flex-start;
}
.BusinessSection .BSRight .card-content .ant-card-body .CardSpan {
  margin-top: 6px;
  margin-left: 13px;
}
.BusinessSection .BSRight .card-content .ant-card-body .CardSpan h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  margin: 0px 0px 3px 0px;
}
.BusinessSection .BSRight .card-content .ant-card-body .CardSpan p {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.63px;
  /* text-align: left; */
  color: #7d7d7d;
}
.List-margin-left {
  margin-left: 10px;
}
.BusinessSection .BSRight .CheckMark {
  font-size: 16px;
  background-color: #2d358c;
  color: white;
  border-radius: 20px;
  padding: 3px;
}

/*################################  BusinessSection style  ###################################*/



/*################################  AboutBusinessSection style  ###################################*/


#AboutBusinessSection {
  background-color: #ffff;
}

.AboutBusinessSection {
  /* display: flex; */
  padding: 6rem 0rem;
}

.AboutBusinessSection .BSleft2nd {
  display: flex;
  justify-content: flex-end;
}

.AboutBusinessSection .BSleft .BSleft-content {
  max-width: 400px;
}

.AboutBusinessSection .BSleft .BSleft-content .ImgContainer {
  max-width: 100%;
  min-width: 331px;
}



.AboutBusinessSection .BSleft .BSleft-content .ImgContainer .BSleftimg {
  width: 100%;

  border-radius: 10px;
}

.AboutBusinessSection .BSleft .BSleft-content h4 {
  font-size: 24px;

  line-height: 36.3px;

  margin-top: 1rem;
  overflow-wrap: break-word
}

.AboutBusinessSection .BSleft .BSleft-content p {
  font-size: 13px;
  font-weight: 400;
  color: #7d7d7d;
  line-height: 26.31px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.AboutBusinessSection .BSleft .BSleft-content .InTouchbtn {
  border: none;

  border-bottom: 2px solid;
  padding: 0;
  background-color: white;
  /* font-family: Inter; */
  font-size: 18px;
  font-weight: 600;
}

.AboutBusinessSection .BSRight .BSRight-content .ant-list-item {
  display: flex;
  justify-content: flex-start;
  border: 1px solid #E3E3E3;
  padding: 20px;
  margin-bottom: 2rem;
}


.AboutBusinessSection .BSRight .BSRight-content {
  /* max-width: 637px; */
}

.AboutBusinessSection .BSRight .BSRight-content h5 {
  font-size: 16px;
  font-weight: 500;
}

.AboutBusinessSection .BSRight .BSRight-content h3 {
  /* font-size: 40px;
  font-weight: 700; */
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2rem;
}

.AboutBusinessSection .BSRight .BSRight-content p {
  font-weight: 400;
  line-height: 1.6em;
  font-size: 17px;
  color: black;
}

.AboutBusinessSection .BSRight .BSRight-content button {
  padding: 19px 30px 19px 30px;
  gap: 10px;
  border-radius: 5px;

  background-color: #2d358c;
  border: none;
  margin-top: 1.5rem;
  color: white;
}

.AboutBusinessSection .BSRight .BSRight-content .ant-list {
  margin-bottom: 3rem;
}
.AboutBusinessSection .BSRight .BSRight-content .ant-list-item .ListItemSpan {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.AboutBusinessSection .BSRight .BSRight-content .ant-list-item .ListItemtext {
  font-family: Poppins;
  font-size: 19px;
  font-weight: 400;
  line-height: 30px;
}

.AboutBusinessSection .BSRight .card-content .ant-card-body {
  display: flex;
  align-items: flex-start;
}
.AboutBusinessSection .BSRight .card-content .ant-card-body .CardSpan {
  margin-top: 6px;
  margin-left: 13px;
}
.AboutBusinessSection .BSRight .card-content .ant-card-body .CardSpan h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  margin: 0px 0px 3px 0px;
}
.AboutBusinessSection .BSRight .card-content .ant-card-body .CardSpan p {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.63px;
  /* text-align: left; */
  color: #7d7d7d;
}
.List-margin-left {
  margin-left: 10px;
}
.AboutBusinessSection .BSRight .CheckMark {
  font-size: 16px;
  background-color: #2d358c;
  color: white;
  border-radius: 20px;
  padding: 3px;
}
.SectionSpacer{
  margin:   4.5rem;
}
/*################################  AboutBusinessSection style  ###################################*/

/************************************* AccountService Style *****************************************/
#AccountService {
  padding-bottom: 5rem;
  padding-top: 1rem;
}

.AccountServiceSection {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.AccountServiceSection-content {
  width: 777px;
  max-width: 777px;
  /* padding: 50px 23px 40px 23px; */
}
.AccountServiceSection-content h2 {
  font-size: 40px;
  font-weight: 700;
}
.AccountServiceSection-content p {
  font-size: 17px;
  font-weight: 400;
}

#AccountService .AccountServiceSection .AccountServiceSection-content h3 {
  font-family: system-ui;
  font-size: 38px;
  font-weight: 800 !important;
  line-height: 48.4px;
  text-align: center;
}
.AccountServiceSection-content .GetStarted {
  border-radius: 5px;
  color: white;

  background-color: #2d358c;
  padding: 14px 30px;
  border: none;
}

/************************************* AccountService Style *****************************************/

/************************************* AiServiceSection Style *****************************************/
#AiSection {
  background-image: url("../images/Aibg.jpg");
  padding: 3rem 0;
}
.AiSection {
  color: white;
}
.AiSection .leftcolcontent {
  /*display: flex;*/
  flex-wrap: wrap;
  width: 100%;
}
.AiSection .leftcolcontent h5 {
  color: white;
}
.AiSection .leftcolcontent .AiSectionHeading {
  font-size: 2rem;
  overflow-wrap: break-word;
}
.AiSection .leftcolcontent p {
  color: white;
  margin-bottom: 4px;
}
.AiSection .leftcolcontent .AICharts {
  display: flex;
  gap: 1rem;
  margin-top: 3rem;
}
.AiSection .leftcolcontent .AICharts .chart {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  background-color: #2e2f34;
  padding: 27px 30px 27px 30px;
}
.AiSection .AiImgContainer img {
  height: 100%;
  width: -webkit-fill-available;
}

/************************************* AiServiceSection Style *****************************************/

/************************************* ContactUsSection Style *****************************************/
#ContactUsSection {
  /*background-color: #2d358c;*/
  padding: 5rem 0rem;
  margin-bottom: 3rem;
}

.ContactUsSection-content .leftcolcontent {
  color: #2d358c;
}
.ContactUsSection-content .leftcolcontent .imgcontainer {
  max-width: 230px;
  height: 138px;
  padding-top: 3rem;
}
.ContactUsSection-content .leftcolcontent .imgcontainer .ContactFormLogo {
  width: 100%;
}

.ContactUsSection-content .leftcolcontent h1,
.DownloadNowSection-content .leftcolcontent h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 75.5px;
}
.ContactUsSection-content .leftcolcontent p,
.DownloadNowSection-content .leftcolcontent .para {
  font-size: 22px;
  font-weight: 400;
  line-height: 32.16px;
}
.ContactUsSection-content .leftcolcontent .headingtext {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
}
.contactformheading {
  font-size: 27px;
  font-weight: 600;

  margin-bottom: 10px;
  /*color: white;*/
  color: #44b876;
}
.downloadText {
  margin-top: 4rem;
}
/* .form {
  margin: 11rem auto;

  margin-top: 5rem;
} */

.ContactUsForm {
  border-radius: 10px;
  background-color: #3d4598;
  padding: 2rem 2rem;
}

.ContactUsForm .FormTitle {
  font-size: 40px;
  font-weight: 700;
  line-height: 48.4px;
}
.ContactUsForm .ant-row {
  justify-content: center;
}
.ContactUsForm .ant-row .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
}
.ContactUsForm .ant-row .ant-form-item-control-input-content .ant-input-affix-wrapper {
  display: flex;
  justify-content: space-between;
}
.ContactUsForm .ant-row .ant-form-item-control-input-content .ant-input-affix-wrapper input {
  border: none !important;
}
.ContactUsbtn .ant-form-item-control-input {
  float: left;
}
.ContactUsbtn .ant-form-item-control-input Button {
  /* width: 100%; */
  background-color: #44b876;
  height: 65px;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);

  padding: 21px 61px 22px 61px;
}
.ContactUsbtn .ant-form-item-control-input Button:hover {
  background-color: #44b876 !important;
}
.ContactUsForm .ant-form-item {
  margin-bottom: 8px;
}
.ContactUsForm .ant-input,
.ant-picker {
  height: 58px;
  width: 100%;
  border: 1px solid #dddddd !important;
  border-radius: 5px;
}
.ContactUsForm .textArea .ant-input {
  height: auto;
}
.ContactUsForm.ant-row .ant-input::placeholder {
  color: #37393f !important;
}

/************************************* ContactUsSection Style *****************************************/

/************************************* DownloadNowSection Style *****************************************/
#DownloadNowSection {
  background-color: #000;
  padding-top: 5rem;
}
.DownloadNowSection .leftcol {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.DownloadNowSection .rightcol {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.DownloadNowSection .rightcol .imgcontainer {
  max-width: 433px;
  max-height: 741px;
}
/* .DownloadNowSection .imgcontainer{
  height: 580px;
  width: 430px;
}
.DownloadNowSection .imgcontainer img{
  height: 100%;
  width: 100%;
} */
.DownloadNowSection-content .leftcolcontent h1 {
  color: white;
}
.DownloadNowSection-content .leftcolcontent p {
  color: white;
}
.DownloadNowSection-content .leftcolcontent .btncontainer {
  display: flex;
  gap: 16px;
}

.DownloadNowSection-content .leftcolcontent .btncontainer a .buttoncontent {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.DownloadNowSection-content .leftcolcontent .btncontainer a {
  height: 75px;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #000000;
  text-decoration: none;
  background-color: white;
  display: flex;
  text-align: left;
}
.DownloadNowSection-content .leftcolcontent .btncontainer a span p {
  color: #a7a7a7;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  text-decoration: none !important;
}

.DownloadNowSection-content .leftcolcontent .btncontainer .ant-btn-icon {
  color: #a7a7a7;
}
.DownloadNowSection-content .leftcolcontent .btncontainer a .buttoncontent img {
  margin-right: 1rem;
}

/************************************* DownloadNowSection Style *****************************************/

/************************************* PricingSection Style *****************************************/
.PricingSection {
  margin: 4rem auto;
}
.PricingSectionheader {
  text-align: center;
  margin-bottom: 4rem;
}
.PricingSection .ant-card {
  border-radius: 8px;
  box-shadow: none;
  height: 100%;
  background-color: #f4f4f4;
  text-align: center;
  padding: 50px 24px;
}
.PricingSection .ant-card .ant-card-head {
  font-size: 30px;
  font-weight: 700;
  line-height: 33px;
}
.PricingSection .ant-card-body {
  padding: 0px;
}
.PricingSection .ant-card .billingInfo {
  /* border: 1px solid #D3D3D3; */
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  padding: 23px 0px;
}
.PricingSection .ant-card .billingInfo h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  padding-bottom: 23px;
}
.PricingSection .ant-card .billingInfo h2 span {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
}
.PricingSection .ant-card .billingInfo div {
  display: flex;
  justify-content: center;
}
.PricingSection .ant-card .billingInfo div p {
  margin: 0;
  width: fit-content;
  padding: 7px 32px 7px 32px;
  gap: 10px;
  border-radius: 5px;

  background: #44b876;
  color: white;

  font-size: 16px;
  font-weight: 400;
  line-height: 28.33px;
}

/*  */
.advancedpackage {
  background-color: #2d358c !important;
  padding-top: 50px;
}
.PricingSection .advancedpackage {
  color: white;
}
.PricingSection .advancedpackage .ant-card-head {
  color: white;
}
.PricingSection .advancedpackage .billingInfo div p {
  background-color: white !important;
  color: #2d358c;
}
.PricingSection .advancedpackage .features-list li {
  color: white !important;
}
.PricingSection .advancedpackage .features-list .features-list-item {
  justify-content: start !important;
  text-align: start;
}
.PricingSection .advancedpackage .GetStartedBtn button {
  background-color: white !important;
  color: #2d358c;
}
.PricingSection .advancedpackage .GetStartedBtn button:hover {
  background-color: white !important;
  color: #2d358c !important;
}

.PricingSection .popular-card {
  position: relative;
  transform: scale(1.1);
  z-index: 1;
}

.PricingSection .popular-label {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #44b876;
  color: white;
  padding: 5px 10px;
  border-radius: 10px 10px 0px 0px;

  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  width: 100%;
}

.PricingSection .ant-card {
  transition: transform 0.3s ease;
}

.ant-card-head {
  font-weight: 600;
}
.PricingSection .ant-card {
  height: 100%;
}

/************************************* PricingSection Style *****************************************/

/************************************* TestimonialsSection Style *****************************************/
#TestimonialsSection {
  background-color: #ffffff;
}
.TestimonialsSection {
}
.TestimonialsSection {
  padding: 50px 0;
}

.leftcolcontent h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;

  color: #000000;
}

.TestimonialsSection .leftcolcontent h1 {
  font-size: 36px;
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 700;
  line-height: 48.4px;
}

.leftcolcontent .textcolorgreen {
  color: #28a745;
}

.leftcolcontent .para {
  color: #666;
  font-size: 17px;
  font-weight: 400;
  line-height: 26.31px;
  text-align: left;
}

.TestimonialsSection {
  padding: 50px 0;
}

.TestimonialsSection .leftcolcontent h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.TestimonialsSection .leftcolcontent .textcolorgreen {
  color: #28a745;
}

.leftcolcontent .para {
  font-size: 16px;
  color: #666;
}
.testimonial-heading-content {
  display: flex;
  text-align: left;
}
.testimonial-item-content .reviews {
  font-style: italic;

  font-size: 20px;

  font-weight: 400;
  line-height: 32.16px;
  text-align: left;
  color: #7d7d7d;
}
.testimonial-info {
  text-align: left;
}
.testimonial-pair {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

.testimonial-item {
  text-align: center;
  padding: 20px;
  flex: 1;
}

.testimonial-item .testimonial-heading-content img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 20px;
  margin-right: 1rem;
}

.testimonial-item h3 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.testimonial-item p {
  font-size: 16px;
  color: #666;
  margin-bottom: 0;
}

.testimonial-item {
  display: flex !important;
  flex-direction: row;
  gap: 2rem;
}
.testimonial-item-content {
  border-radius: 10px;
  background-color: #f6f6f6;
  max-width: 411px !important;
  width: 100%;
  padding: 24px;
}
.stars .anticon {
  color: #ffa722;
  width: 20.09px;
  height: 19.19px;
}
/************************************* TestimonialsSection Style *****************************************/

/************************************* OcrSection Style *****************************************/

.ocrsection {
  padding: 6rem 0rem;
}

.ocrheader {
  margin-bottom: 6rem;
}

.SectionHeadingcontent {
  text-align: center;
}

.SectionHeadingcontent h1 {
  font-size: 38px;
  font-weight: 700;
  line-height: 48.4px;
}

.SectionHeadingcontent p {
  font-size: 17px;
  font-weight: 500;
  line-height: 21.78px;
}

.ocrsection .ant-tabs .ant-tabs-nav::before {
  border: none;
}

.custom-tab-label .ant-tabs-nav {
  display: flex;
  justify-content: space-between;
}

.tabLabel {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 5px;
}

.labelcontainer {
  display: flex;
  align-items: center;
}

.labelNo {
  margin-right: 8px;
}

.labeltext p {
  margin: 0;
}

.ocrStepArrow {
  position: absolute;
  right: -37px;
  rotate: 92deg;
  color: green;
  font-size: medium;
}
/* Rotate arrow for Arabic (RTL) */
.rotate-arrow {
  transform: rotate(180deg);
}
.ant-tabs-nav-operations {
  display: none !important;
}

.ant-tabs-content .ant-row {
  justify-content: space-evenly;
}

.ant-tabs-content .ant-row .tabText {
  padding-top: 1rem;
}

.ant-tabs-content .ant-row .tabText h1 {
  font-weight: 600;
  line-height: 48px;
  font-size: 34px;
}

.ant-tabs-content .ant-row .tabText p {
  font-size: 19px;
  font-weight: 400;
  line-height: 32px;
}

.ant-tabs-tab-active {
  border: 1px solid #44b876 !important;
  border-radius: 10px;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black !important;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs .ant-tabs-tab:hover {
  color: black !important;
}

.ant-tabs .ant-tabs-tab {
  margin: 0 0 0 46px;
}

.ant-tabs-ink-bar {
  display: none !important;
}

.custom-tab-label .labelcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-tab-label .labelcontainer .labelNo {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-right: 15px;
  font-weight: 700;
}

.custom-tab-label .labelcontainer .labeltext p {
  margin: 0;
}

.labelcontainer .labeltext p:first-child {
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  padding-bottom: 5px;
}

.labelcontainer .labeltext p:nth-child(2) {
  font-size: 16px;
  font-weight: 300;
  line-height: 23.39px;
}

.ant-tabs-tab {
  padding: 12px 15px !important;
}

.ant-tabs-content {
  margin-top: 4rem;
}

.imgcontainer .OcrImages {
  width: 100%;
}

/* RTL Specific Styles */
.rtl .labelNo {
  margin-right: 0;
  margin-left: 15px;
  margin-right: 0;
}

.rtl .tabLabel {
  flex-direction: row-reverse;
  padding-right: 0;
  padding-left: 5px;
}

.rtl .ocrStepArrow {
  left: -37px;
  right: auto;
}

.rtl .ant-tabs .ant-tabs-tab {
  margin: 0 46px 0 0;
}

/************************************* AccountService Style *****************************************/

/*################################ Signup Style ##################################*/
/* .form {
  margin: 11rem auto;

  margin-top: 5rem;
} */
label.ant-form-item-required::before {
  content: none !important;
}
.SignUp_form {
  border-radius: 10px;
  /* background-color: #f8f8f8; */
  padding: 2rem 0rem;
}
.logocontainer {
  text-align: center;
}
.SignUp_form .SignTwallamlogo {
  /* padding: 1rem 0rem; */
  padding-bottom: 2rem;
}
.GoogleLoginBtn iframe {
  width: 100% !important;
}
.GoogleLoginBtn .qJTHM #container {
  width: 100% !important;
  background-color: #28a745;
}
.GoogleLoginBtn iframe .qJTHM #container {
  width: 500px !important;
  background-color: #333333;
}
.GoogleLoginBtn iframe body div {
  width: 100% !important;
  background-color: #333333;
}
.GoogleLoginBtn body.qJTHM div#container {
  width: 100% !important;
  background-color: #333333;
}

.SignUp_form .FormTitle {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 48.4px;
  text-align: center;
  margin-bottom: 4rem;
}
.SignUp_form .formHeading {
  text-align: center;
}
.SignUp_form .ant-row {
  justify-content: center;
}
/* .SignUp_form .ant-row .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
} */

.SignUp_form .ant-row .ant-radio-wrapper {
  font-size: 14px;
  font-weight: 500;
  line-height: 26.73px;

  color: #2d358c;
  border: 1.5px solid #dddddd;

  padding: 10px 30px 10px 30px;

  border-radius: 5px;
  border: 1.5px 0px 0px 0px;
}
.SignUp_form .ant-row .ant-radio-wrapper .ant-radio-inner {
  /* border-color: white !important; */
  border: 1px solid #dddddd !important;
}
.SignUp_form .ant-row .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: white;
  background-color: white;
}

.SignUp_form .ant-row .ant-form-item-control-input-content .ant-input-affix-wrapper {
  display: flex;
  justify-content: space-between;
}
.SignUp_form .ant-row .ant-form-item-control-input-content .ant-input-affix-wrapper input {
  border: none !important;
}
.Signupbtn .ant-form-item-control-input {
  float: right;
  width: 100%;
}
.Signupbtn .ant-form-item-control-input Button {
  width: 100%;
  background-color: #2d358c;
  height: 45px;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.Signupbtn .ant-form-item-control-input Button:hover {
  background-color: #2d358c !important;
}
.SignUp_form .ant-input-outlined {
  height: 48px;
  background-color: #f1f4f9;
  border: 1px solid #dddddd !important;
  border-radius: 5px;
}
.SignUp_form .ant-row .ant-input::placeholder {
  color: #a6a6a6 !important;
}
.SignUp_form .login-form-forgot {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.82px;
  text-align: center;
  color: #333333 !important;
  text-decoration: none;
}
.SignUp_form .login-form-forgot span {
  font-size: 16px;
  font-weight: 600;
  line-height: 21.82px;
  text-align: center;
  color: #2d358c;
}
.ant-row .line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #cbcbcb;
  line-height: 0.01em;
  margin: 2rem 0px;
}
.ant-row .line .lineText {
  background: #fff;
  padding: 0 15px;

  color: #333333;
  text-align: center;

  font-size: 12px;
  font-weight: 400;
}
.ant-row .line .lineText .spantext {
  color: #2d358c;
}

/*  */
.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Signupbtn {
  padding: 8px 0;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
}
.Signupbtn button {
  background-color: #2d358c;
  width: 100%;
  height: 48px;
}
.Signupbtn button:hover {
  background-color: #2d358c !important;
}

.SignupbtnGoogle {
  background: none;
  border: 1px solid #a5a5a5;
  color: #80868b;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  line-height: 27.28px;
  width: 100%;
  height: 48px;
}
.SignupbtnGoogle span {
  margin-left: 12px;
}
.SignInLink {
  margin-top: 1rem;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}
.SignInLink a {
  color: #44b876;
}
.log-button a {
  color: inherit;
  text-decoration: none;
  width: 100%;
  height: 48px;
}

.bottom-Image {
  max-width: 600px;
}
.bottom-Image img {
  width: 100%;
}
.loginright {
  display: flex;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.loginright .signupFooter {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}
.loginright .signupFooter .footerspantext {
  color: #44b876;
}

.radioGroup {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.radioButton {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #dddddd;
  padding: 10px 20px;
  border-radius: 5px;
  color: #2d358c;
  font-size: 20px;
  font-weight: 500;
  line-height: 26.73px;
  margin: 0px;
  transition: background-color 0.3s, color 0.3s;
}

.radioButton .ant-radio {
  margin-right: 8px;
}

.radioButton .ant-radio-checked .ant-radio-inner:after {
  background-color: #2d358c !important;
}

.radioButton.ant-radio-wrapper-checked {
  background-color: #2d358c;
  color: white !important;
  border-color: #2d358c;
}

/*################################ Signup Style ##################################*/
/* thank you*/
#thankyouform .form {
  margin-bottom: 6rem;
  padding-bottom: 2rem;
  margin-top: 1rem;
}

/* thank you*/
/*################################ Pricing Page Style ##################################*/
.Pricing {
  padding: 6rem 0;
  text-align: center;
}
.TextSection {
  text-align: center;
  margin-top: 5rem;
}
.TextSection h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 48.4px;
}
.TextSection p {
  font-size: 18px;
  font-weight: 400;
  line-height: 26.31px;
  color: #7d7d7d;
  margin-bottom: 0;
}

.Pricing p,
.PricingSectionheader p {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: center;
}
.Pricing h1,
.PricingSectionheader h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 48.4px;
  margin-bottom: 2.5rem;
}
.Pricing h1 span {
}
.pricingheader {
  background-image: url("../images/pricingtopbg.png");
  background-position: right;
  background-size: cover;

  min-height: 376px;
  height: 450px;
  display: flex;
  align-items: center;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), transparent), url("../images/pricingtopbg.png");
}
.pricingheadercontent {
  margin-left: 8rem;
}
.Pricing .ant-card {
  border-radius: 8px;
  box-shadow: none;
  height: 100%;
  background-color: #f4f4f4;
  text-align: center;
  padding: 24px;
}
.Pricing .ant-card .ant-card-head {
  font-size: 30px;
  font-weight: 700;
  line-height: 33px;
}
.Pricing .ant-card-body {
  padding: 0px;
}
.Pricing .ant-card .billingInfo {
  /* border: 1px solid #D3D3D3; */
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  padding: 23px 0px;
}
.Pricing .ant-card .billingInfo h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  padding-bottom: 23px;
}
.Pricing .ant-card .billingInfo h2 span {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
}
.Pricing .ant-card .billingInfo div {
  display: flex;
  justify-content: center;
}
.Pricing .ant-card .billingInfo div p {
  margin: 0;
  width: fit-content;
  padding: 7px 32px 7px 32px;
  gap: 10px;
  border-radius: 5px;

  background: #44b876;
  color: white;

  font-size: 18px;
  font-weight: 400;
  line-height: 28.33px;
}

/* Features List Styles */
.features-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: start;
  margin-top: 34px;
  margin-bottom: 34px;
}

.features-list li {
  margin-bottom: 13px;
  color: #7d7d7d;
  font-weight: 400;
  font-size: 16px;
  font-weight: 400;
}
.Pricing .ant-btn-primary:hover,
.Pricing .ant-btn-primary:focus {
  background-color: #2d358c;
  border: none;
}
.GetStartedBtn {
  display: flex;
  justify-content: center;
}
.GetStartedBtn button {
  padding: 24px 36px;
  gap: 10px;
  border-radius: 5px;

  font-weight: 500;
  border: none;
  background-color: #2d358c;
  display: flex;
  align-items: center;
}
.GetStartedBtn button:hover {
  background-color: #2d358c !important;
}

/*################################ Pricing Page Style ##################################*/

/*################################ OtP Page Style ##################################*/

.OtpForm .ant-row {
  justify-content: center;
}
.OtpForm .ant-input {
  height: 48px;
  border: 1px solid #dddddd !important;
  border-radius: 5px;
}
.otpsectionheading p {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
}
.otpsectionheading {
  margin-bottom: 4rem;
}

.otpheading p {
  color: #7d7d7d;

  font-size: 18px;
  font-weight: 400;
  line-height: 26.31px;
}
.otpheading .SectionHeadingcontent {
  margin-bottom: 2rem;
}
.otpheading h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 38.72px;
}
.resendBtnInput .ant-row .ant-form-item-control-input-content {
  display: flex;
  justify-content: center !important;
}
.btnStartingText {
  color: #7d7d7d;
}
.resendbtn {
  font-size: 18px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: center;
  color: #2d358c;
  background: none;
  height: 23px;
  padding: 0px 7px;
  border-radius: 6px;
  margin: 0;
  box-shadow: none;
}
.resendbtn:hover {
  background: none !important;
  color: #2d358c !important;
}
.OtpForm .backbtn {
  border: 2px solid #2d358c;
  color: #2d358c;
  background-color: white !important;
}

/*################################ OtP Page Style ##################################*/



/*################################ AboutPage Style ##################################*/



/* //our process section */

.process-section {
  background-color: #f8f9fa;
  padding: 60px 0;
}

.process-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.process-top-title {
  text-align: center;

  font-size: 18px;
  font-weight: 500;
  line-height: 27px;


  color: #37393F;
}
.process-title {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 4.3rem;
  color: #333;
}

.process-card {
  background-color: #F4F4F4;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensure cards are the same height */
  /* border-top: 5px solid #007bff;  */
}

.process-card:hover .process-icon {
  background-color: #2D358C;
}

.process-icon {

  font-size: 48px;
  margin-bottom: 20px;
  width: 72px;
  height: 72px;
  border-radius: 50px;
  position: absolute;
  right: 0;
  left: 0;
  top: -32px;
  margin: auto;
  display: flex;
  justify-content: center;
  background-color: #44B876;
}
.process-icon img{


  width: 38px;


}

.process-heading {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
}

.process-description {

  font-size: 18px;
  font-weight: 400;
  line-height: 26.31px;
  text-align: center;
  color: #7D7D7D;
  margin-bottom: 25px;
}

.process-button-container {
  margin-top: auto;
}

.process-button {
  text-decoration: none;
  color: #2D358C;

  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: center;

}

.process-button:hover {
  color: #2D358C;
}

/* Additional top border styles for different cards */

/* / //our expert section / */

.expert-section {
  background-color: #f8f9fa;
  padding: 60px 0;
  box-sizing: border-box;
}

.expert-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.expert-header {
  text-align: center;
}

.expert-header .expert-title {
  font-size: 18px;
  font-weight: 500;
  color: #37393F;
  padding-left: 25px;
  position: relative;
  margin: 0 auto 1px;
  display: inline-block;
}

.expert-header .expert-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  background-color: #4EE1B1;
}

.expert-header .expert-subtitle {
  font-size: 40px;
  font-weight: 700;
  line-height: 48.4px;
  color: #37393F;
}

.expert-card {
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease;
  overflow: hidden;
  position: relative;
}

.expert-card .expert-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2D358CE5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translateY(100%);
  transition: 0.5s ease;
}

.expert-card:hover .expert-icon {
  transform: translateY(0);
}

.expert-icon .expert-social {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  background: green;
  border: 0.2rem solid var(--main-color);
  border-radius: 50%;
  font-size: 1.5rem;
  color: white;
  margin: 3rem 1rem ;
  transition: 0.2s ease;
}

.expert-card .expert-info {
  width: 90%;
  bottom: 1rem;
  left: 1rem;
  position: absolute;
  background: #ffffff;

}

.expert-button {
  color: #37393F;
  text-decoration: underline;
  font-size: 1.5rem;
  font-weight: 600;
}

.expert-button-container p {
  font-weight: 400;
}

.expert-button:hover {
  color: rgb(0, 0, 0);
}
/* / //our expert section / */



/* ************** statistics Section ************* */
.statistics-section {
  background-color: #ffffff; /* White background */
  padding: 60px 0; /* Space around the section */
}



.statistics-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px; /* Space between cards */
}

.statistics-card {
  flex: 1;

  text-align: center;
  padding: 20px;

  border-radius: 8px;

  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}



.icon {
  margin-bottom: 15px;
  box-shadow: 0px 0px 14px 11px #F1F1F170;
  width: 72px;
  height: 72px;
  display: flex;
  border-radius: 50%;

  background-color: white;


  justify-content: center;
  align-items: center;
}

.statistics-card h3 {
  font-size: 32px;
  color: #28a745; /* Green color for numbers */
  margin-bottom: 10px;
  font-weight: bold;
}

.statistics-card .statistics-bottom-title {

  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: center;
  color: #2D358C;

}
.statistics-card  .statistics-bottom-text{

  font-size: 18px;
  font-weight: 400;
  line-height: 26.31px;
  text-align: center;
  color: #7D7D7D;
}

.seperator {
  width: 1px; /* Width of the divider */
  background-color: #ccc; /* Color of the divider */
  height: 100%; /* Full height of the container */
}





/* ************** statistics Section ************* */

/* our video section */

.video-section {
  padding: 5rem 0;
}

.video-section .video-box {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.6rem;
  background: #40DDB6;
}


.video-section .video-para {
  font-size: 0.8rem;
}

.video-section .video-icon {
  color: #6B77E5;
  font-size: 3rem;
  background-color: #ffffff;
  border-radius: 50%;
}





/*################################ AboutPage Style ##################################*/




/*************************************Footer Style *****************************************/

/******************* footer SearchBar ***********************/
.footer-top {
  position: relative;
}



/*Resize the wrap to see the search bar change!*/
.wrap {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #44b876;
  height: 120px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.search {
  width: 80%;
  position: relative;

  display: flex;
  /* margin: 2rem; */
  border: none;
  /* background-color: aquamarine; */
  /* height: 20px; */
  border-radius: 5px;
  justify-content: center;
  margin: 0 auto; /* Centering using margin */
}

.searchTerm {
  width: 100%;
  /* height: 50px !important; */
  /* border: 2px solid #00b4cc; */
  /* border-right: none; */
  /* padding: 3px; */
  padding-left: 1rem;
  height: 20px;
  /* border-radius: 5px 0 0 5px; */
  border: none;
  outline: none;
  color: #9dbfaf;
}

.searchTerm .emailInput {
  height: 50px !important;
  border-radius: 5px 0 0 5px !important;
}

.searchTerm:focus {
  color: #00b4cc;
}

.searchButton {
  height: 50px;
  border: none;
  background: #2d358c;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;

  padding: 11px 40px 11px 40px;
}

/******************* footer SearchBar ***********************/
.Footer {
  background-color: #2d358c;

  width: 100%;
  padding-bottom: 10px;
  margin-top: auto;
  /* Prevents the footer from shrinking when content is added */
}

.Footer .ant-divider-horizontal {
  background-color: #d9d8d8;
}

.Footer .footer-middle {
  padding-top: 7rem;
}

.Footer .footer-middle .anticon {
  width: 55px;
  height: 55px;
  /* gap: 0px; */

  background: #ffffff;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer .footer-middle .anticon svg {
  width: 26px;
  height: 26px;
  top: 21px;
  left: 23px;
  gap: 0px;
  border: 2px 0px 0px 0px;

  color: #44b876;
}
.Footer .footer-middle .footer_social_icon {
  display: flex;
  margin-top: 3rem;
  gap: 27px;
  flex-wrap: wrap;
}
.Footer .footer-middle .footer_social_icon .anticon {
  width: 25.93px;
  height: 25.93px;
  left: 155.34px;
  gap: 0px;
  background: transparent;
}

.footer_social_icon a .fbLogo {
  width: 25.93px;
  height: 25.93px;
}
.Footer .footer-middle .footer_social_icon .anticon svg {
  width: 24.09px;
  height: 24.46px;
  color: #ffffff;
}

.Footer .footer-middle .contact-box,
.mail-box,
.location-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Footer .footer-middle .contact-box .anticon svg {
  transform: rotate(100deg);
  width: 26px;
  height: 26px;
  top: 21px;
  left: 23px;
  gap: 0px;
  border: 2px 0px 0px 0px;

  color: #44b876;
}

.Footer .footer-middle .info .ant-typography {
  /* font-family: Poppins; */
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin: 0;
}

.Footer .footer-middle .info p {
  color: white;
  font-weight: 400;
  margin: 0;
}

.Footer .footer-middle .ant-list-split .ant-list-item {
  color: white;
  border-block-end: none;

  padding: 8px 0px;
}

.Footer .footer-middle .ant-list-split .ant-list-item a {
  color: white;
  border-block-end: none;
  text-decoration: none;
}

.Footer .footer-middle .ant-list-split .ant-list-item a:hover {
  color: #44b876;
}

.Footer .footer-middle .footer-middle-heading {
  color: #44b876;

  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.footer-bottom {
  text-align: center;
  color: white;
}


/* Styling for the div containing the logo */
.footerLogoContainer {
  max-width: 190px;
  width: 100%;

}

/* Styling for the logo image */
.FooterLogo {
  width: 100%;
  height: auto;
}




/*************************************Footer Style *****************************************/
.stripeheadingtext {
  color: #37393f;

  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: center;
}
.stripeheading {
  margin-bottom: 2rem;
  font-size: 40px;
  font-weight: 700;
  line-height: 48.4px;
}
.paymentcontainer {
  background-color: #f8f8f8;
  border-radius: 10px;

  padding-bottom: 5rem;
  padding-top: 5rem;
}
/* .paymentcontainer .stripelogocontainer{
  padding: 2rem 0;
  height: 50px;
  width: 150px;
  margin-bottom: 3rem;
}
.paymentcontainer .stripelogocontainer .stripelogo{
  height: 100%;
  width: 100%;
} */
.paymentcontainer .paymentHeading {
  margin-bottom: 1rem;
}
.paymentcontainer .ant-row {
  justify-content: center;
}
.paymentcontainer .StripeElement {
  background-color: white !important;
  /* border: 1px solid #ddd !important; */
  box-shadow: 0 5px 4px 0 #e6ebf1;
  margin-bottom: 2rem;
  border-radius: 5px;
  padding: 15px 12px;
}
.StripeElement .__PrivateStripeElement {
  background-color: white;
  border: 1px solid #ddd !important;
  border-radius: 5px;
  padding: 15px !important;
}

.btn-pay {
  background-color: #2d358c;
  box-shadow: 0 2px 0 #0591ff1a;
  height: 45px;
  width: 100%;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 16px;
}



/*********************************************************
              Contact Page css
**********************************************************/
#contact .ContactDetailSection {


}
#contact .ContactDetailSection .ant-input::placeholder {

  font-weight: 400;
  line-height: 27px;
  color: #37393F;

  
}
#contact .ContactDetailSection .ant-input-outlined{
  height: 58px;
  width: 100%;
  padding: 4px 30px;
  border-radius: 5px;
  border: 1px solid #DDDDDD;

}
#contact .ContactDetailSection textarea{
  height: 200px !important;
  padding-top: 24px !important;

}

#contact .ContactDetailSection h5{
text-align: center;

}
#contact .ContactDetailSection .contact-heading{
  font-size: 33px;
  font-weight: 700;
  line-height: 48.4px;
  text-align: center;
}
#contact .ContactDetailSection .contactPageSubmitContainer{
  text-align: center;
}
#contact .ContactDetailSection .contactPageSubmit{

  background-color: #2D358C;
  border-color: #d9d9d9;
  color: white;
  height: 58px;
  padding: 3px 39px;
  border-radius: 5px;
}


/*********************************************************
            Contact Page css
**********************************************************/



/*********************************************************
              Floating button css
**********************************************************/
.positionRelative{
  position: relative;
}
.floating-button {
  position: fixed;
  /* right: 20px; */
  padding: 15px 20px 15px 20px !important;
  font-size: 16px;
  background-color: #44b876;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: bottom 0.3s ease-in-out, top 0.3s ease-in-out;
  z-index: 9999 !important;
}

.floating-button.top {
  /* top: 20px; */
  bottom: 56px;
  left: 147px;
  position: absolute;
}
.floating-button.top.scrollheightbutton{
  position: fixed !important;
  top: auto;
  bottom: auto;
  /* left: 0; */
}
.floating-button.bottom {
  bottom: 20px !important;
  left: 147px;

}
.wrapper-Arabic .floating-button.top{
  right: 147px;
  position: absolute;
  left: auto;
}
.wrapper-Arabic .floating-button.top.scrollheightbutton{
  left: auto;
  right: 0;
}
.wrapper-Arabic  .floating-button.bottom.scrollheightbutton{
  left: auto;
  right: 0;
}

.body-overlay {
  position: fixed;
  background-color: #fbfbfb;
  opacity: 0.6;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
/* service business section style */
.li_height li{
  line-height: 2.2;
}
/* service business section style */

@media only screen and (max-width: 992px) {
  /* Medium screens */
  .ant-card-body,
  .features-list li {
    font-size: 15px;
    line-height: 1.45;
  }
  .BusinessSection {
    flex-wrap: wrap;
    justify-content: space-between;

  }
  .AboutBusinessSection{
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ContactUsSection-content .leftcolcontent h1,
  .DownloadNowSection-content .leftcolcontent h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 70.5px;
  }
  .contactformheading {
    font-size: 26px;
  }
  .ContactUsSection-content .leftcolcontent p,
  .DownloadNowSection-content .leftcolcontent .para {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  .ContactUsForm .ant-input,
  .ant-picker {
    height: 40px;
  }

  .testimonial-item {
    flex-wrap: wrap;
  }
  .leftcolcontent {
    padding-bottom: 2rem;
  }
  .downloadText {
    margin: 0;
  }
  .MiddleNavBar .navbar-nav .SMEbtn {
    margin-bottom: 1rem;
  }
  .footerLogoContainer {
    max-width: 130px;
  }
  .Footer .footer-middle .contact-box, .mail-box, .location-box{
    display: block;
  }
  .seperator {
    display: none; /* Hide separator on screens smaller than 992px */
  }
  .loginright{
display: none;
  }
}

/* Media queries for responsive adjustments */
@media only screen and (max-width: 768px) {
  .top-navbar {
    display: none;
  }
  .MiddleNavBar {
    margin-top: 0rem;
    padding: 1rem 0rem !important;
  }
  /* Small screens */
  .TextSection h1 {
    font-size: 34px;
  }
  .TextSection p {
    font-size: 15px;
  }

  .Pricing h1,
  .PricingSectionheader h1 {
    font-size: 34px;
  }
  .Pricing p,
  .PricingSectionheader p {
    font-size: 15px;
  }
  .Pricing .ant-card-body,
  .features-list li {
    font-size: 14px;
    line-height: 1.4;
  }
  .SectionHeadingcontent h1 {
    font-size: 34px;
    font-weight: 700;
  }
  .SectionHeadingcontent p {
    font-size: 15px;
    font-weight: 500;
  }
  .ant-tabs-tab {
    padding: 8px 12px !important; /* Adjust padding for smaller screens */
  }


  .multiline-text {
    max-width: 200px;
    white-space: normal;
  }

  .labelcontainer {
    width: 250px;
  }

  .labeltext p {
    text-align: start;
    margin: 0;
  }

  .custom-tab-label .labelcontainer .labeltext p {
    margin: 0;
  }
  .labelcontainer .labeltext p:first-child {
    font-size: 16px;
    font-weight: 600;
    line-height: 24.2px;
    padding-bottom: 5px;
  }

  .labelcontainer .labeltext p:nth-child(2) {
    font-size: 16px;
    font-weight: 300;
    line-height: 23.39px;
  }

  .SectionHeadingcontent h1 {
    font-size: 28px;
    font-weight: 700;
    line-height: 48.4px;
  }
  .SectionHeadingcontent p {
    font-size: 15px;
  }
  .ContactUsSection-content .leftcolcontent h1,
  .DownloadNowSection-content .leftcolcontent h1 {
    font-size: 45px;
    font-weight: 700;
    line-height: 58.5px;
  }

  .ContactUsSection-content .leftcolcontent p,
  .DownloadNowSection-content .leftcolcontent .para {
    font-size: 16px;
    font-weight: 400;
    line-height: 28.16px;
  }
  .ContactUsSection-content .leftcolcontent .headingtext {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
  }
  .WelcomeSection-content {
    margin-left: 4rem;
  }
  .WelcomeSection-content h1 {
    font-size: 35px;
    font-weight: 700;
    line-height: 48px;
  }
  .contactformheading {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .ContactUsSection-content .leftcolcontent .imgcontainer {
    max-width: 200px;
    height: 100px;
  }

  #WelcomeSection {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .WelcomeSection-content {
    margin-left: 2rem; /* Adjust margin for smaller screens */
  }

  .WelcomeSection-content h5 {
    font-size: 0.875rem; /* Adjust font size */
  }

  .WelcomeSection-content h1 {
    font-size: 2rem; /* Adjust font size */
    line-height: 1.3;
  }

  .WelcomeSection-content p {
    font-size: 0.875rem; /* Adjust font size */
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  .WelcomeSection-content button {
    padding: 0.75rem 1rem; /* Adjust padding */
  }
  .footerLogoContainer {
    max-width: 130px;
  }
  .Footer .footer-middle .anticon{
    margin-bottom: 1rem;
  }
  .statistics-cards {
    flex-direction: column;
    align-items: center;
  }

  .floating-button.top {

    bottom: 28px;
    left: 41px;
  
}
.floating-button.bottom {

  left: 41px;

}
}
@media (max-width: 576px) {
  .TextSection h1 {
    font-size: 30px;
  }
  .TextSection p {
    font-size: 15px;
  }

  .Pricing h1,
  .PricingSectionheader h1 {
    font-size: 30px;
  }
  .Pricing p,
  .PricingSectionheader p {
    font-size: 15px;
  }
  .Pricing .ant-card .ant-card-head {
    font-size: 26px;
  }
  .Pricing .ant-card .billingInfo h2 {
    font-size: 30px;
    line-height: 30px;
  }
  .Pricing .ant-card .billingInfo div p {
    padding: 7px 26px 7px 26px;
    font-size: 15px;
  }
  .Pricing .ant-card-body,
  .features-list li {
    font-size: 14px;
    line-height: 1.4;
  }
  .GetStartedBtn button {
    padding: 20px 26px;
  }
  .SectionHeadingcontent h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 48.4px;
  }
  .SectionHeadingcontent p {
    font-size: 15px;
  }
  .searchButton {
    font-size: 12px;
    padding: 10px 12px 10px 12px;
  }
  .AccountServiceSection-content h2 {
    font-size: 30px;
    font-weight: 700;
  }
  .AccountServiceSection-content p {
    font-size: 12px;
    font-weight: 400;
  }
  .AccountServiceSection-content .GetStarted {
    padding: 9px 22px;
  }
  .ContactUsSection-content .leftcolcontent h1,
  .DownloadNowSection-content .leftcolcontent h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 52.5px;
  }
  .ContactUsSection-content .leftcolcontent h1 br,
  .DownloadNowSection-content .leftcolcontent h1 br {
    display: none;
  }
  .ContactUsSection-content .leftcolcontent p,
  .DownloadNowSection-content .leftcolcontent .para {
    font-size: 15px;
    font-weight: 400;
    line-height: 28.16px;
  }
  .ContactUsSection-content .leftcolcontent .headingtext {
    font-size: 16px;
    font-weight: 500;
    line-height: 21.78px;
  }
  .AiSection .leftcolcontent .AICharts {
    flex-wrap: wrap;
    justify-content: center;
  }
  .DownloadNowSection-content .leftcolcontent .btncontainer {
    flex-wrap: wrap;
    justify-content: center;
  }
  .DownloadNowSection-content .leftcolcontent .btncontainer a {
    height: 65px;
  }
  .BusinessSection .BSRight .BSRight-content h3 {
    font-size: 30px;
    font-weight: 700;

  }
  .Footer .footer-middle .contact-box, .mail-box, .location-box{
    display: flex;
  }
  .expert-container .ant-row{
    justify-content: center;
  }
  .AboutBusinessSection .BSleft .BSleft-content .ImgContainer {
    max-width: 100%;
    min-width: 267px;
  }
  .AboutBusinessSection .BSRight .BSRight-content p{
    text-align: center;
  }
  .BusinessSection .BSRight .BSRight-content .ant-list-item{
     flex-wrap: nowrap;
  }
}



@media only screen and (max-width: 480px) {
  #WelcomeSection {
    padding: 5px; /* Further adjust padding for very small screens */
  }

  .WelcomeSection-content {
    margin-left: 1rem; /* Further adjust margin */
  }

  .WelcomeSection-content h5 {
    font-size: 0.75rem; /* Further adjust font size */
  }

  .WelcomeSection-content h1 {
    font-size: 1.5rem; /* Further adjust font size */
    line-height: 1.4;
  }

  .WelcomeSection-content p {
    font-size: 0.75rem; /* Further adjust font size */
    margin-top: 0.75rem;
    margin-bottom: 1rem;
  }

  .WelcomeSection-content button {
    padding: 0.5rem 0.75rem; /* Further adjust padding */
  }
  .floating-button.top {

    left: 16px;
  
}
.floating-button.bottom {


  left: 16px;

}
}

main {
  flex: 1;
  /* This allows the main content area to expand to fill the remaining vertical space */
}

/* .wrapper-right{
  -webkit-transform: scale(-1, 1);

  text-align: right;
} */
.wrapper-right {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
  text-align: right;
}

.arabic-text {
  direction: rtl;
  text-align: right;
  font-family: "Your Arabic Font", sans-serif;
}

.wrapper-left {
  text-align: left;
  direction: ltr;
}

.ar-right {
  float: right;
}

/* .ar-top-margin{
  margin-top: 175%;
} */
.privacyPolicy {
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem;
}

.top-margin {
  margin-top: 8%;
}

.ar-text-align {
  text-align: end;
}

.ar-heading-style {
  display: block !important;
  width: 100%;
  text-align: end;
}
.ar-ol-padding li {
  margin-right: 2rem !important;
}

